var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutPage',{attrs:{"title":"Reportes de retenciones"}},[_c('v-expansion-panels',{staticClass:"mb-3",model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{key:0},[_c('v-expansion-panel-header',{staticClass:"outline_none",attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',{staticClass:"primary--text text-subtitle-1 mt-0"},[_vm._v("Filtros")]),_c('v-icon',{attrs:{"size":"33","color":"success"}},[_vm._v(" search ")])]},proxy:true}])}),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"pt-0 mx-md-5"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Mes seleccionado","readonly":"","hide-details":"","persistent-hint":"","value":_vm.moment(_vm.mes).format('MMMM')}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"es-ar","scrollable":"","type":"month","max":new Date().toISOString().substr(0, 10)},model:{value:(_vm.mes),callback:function ($$v) {_vm.mes=$$v},expression:"mes"}})],1)],1),_c('v-col',{staticClass:"d-flex"},[_c('v-spacer')],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.retenciones,"loading":_vm.loading,"loading-text":"Obteniendo registros...","hide-default-footer":"","no-data-text":"Sin registros.","disable-sort":"","page":_vm.page,"items-per-page":_vm.rowsPerPage,"single-expand":true,"expanded":_vm.expanded,"item-key":"numero","show-expand":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.importe))+" ")]}},{key:"item.emitido_el",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fechaEmitido(item.emitido_el))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red darken-2"},on:{"click":function($event){return _vm.generarReporte(item)}}},on),[_c('v-icon',[_vm._v("mdi-file-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Generar reporte")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"orange","icon":""},on:{"click":function($event){return _vm.descargarComprobante(item)}}},on),[_c('v-icon',[_vm._v("mdi-folder-arrow-down")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar comprobante")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"1% !important"},attrs:{"colspan":headers.length,"align":"center"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Código de regimen")]),_c('th',[_vm._v("Gravamen")]),_c('th',[_vm._v("Retener")]),_c('th',[_vm._v("Total retención")]),_c('th')])]),_c('tbody',_vm._l((item.detalles),function(dat){return _c('tr',{key:dat.id,attrs:{"height":"35px"}},[_c('td',{attrs:{"width":"12%"},domProps:{"textContent":_vm._s(dat.id)}}),_c('td',{attrs:{"width":"20%"},domProps:{"textContent":_vm._s(dat.codigo_regimen)}}),_c('td',{attrs:{"width":"25%"}},[_vm._v(_vm._s(dat.gravamen))]),_c('td',{attrs:{"width":"15%"}},[_vm._v(" "+_vm._s(dat.retener ? "Si" : "No")+" ")]),_c('td',{attrs:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.formatImportFloat(dat.total_retencion))+" ")])])}),0)])]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"no-gutters mt-2 mx-2"},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',[_vm._v("Mostrar")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.rowsPerPage)+" "),_c('v-icon',[_vm._v("arrow_drop_down")])],1)]}}])},[_c('v-list',_vm._l((_vm.rowsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.rowsPerPage = number}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1)],1)],1)]},proxy:true}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }