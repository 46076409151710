<template>
  <LayoutPage title="Reportes de retenciones">
    <!-- filtros table  -->
    <v-expansion-panels v-model="panel" class="mb-3">
      <v-expansion-panel :key="0">
        <v-expansion-panel-header class="outline_none" disable-icon-rotate>
          <template v-slot:actions>
            <span class="primary--text text-subtitle-1 mt-0">Filtros</span>
            <v-icon size="33" color="success"> search </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- 1 fila de filtros -->
          <v-row class="pt-0 mx-md-5">
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-menu
                v-model="menu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dark
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Mes seleccionado"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    persistent-hint
                    :value="moment(mes).format('MMMM')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="mes"
                  locale="es-ar"
                  scrollable
                  type="month"
                  :max="new Date().toISOString().substr(0, 10)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="d-flex">
              <v-spacer></v-spacer>
              <!--    <v-btn
                class="green"
                dark
                :loading="loadingGenerar"
                @click="generarCertificacion()"
              >
               iquidar Retenciones
              </v-btn> -->
              <!--  <v-btn
                class="green"
                dark
                :loading="loadingGenerar"
                @click="liquidarRetenciones()"
              >
              Liquidar Retenciones
              </v-btn> -->
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!--end filtros-->

    <v-data-table
      :headers="headers"
      :items="retenciones"
      :loading="loading"
      loading-text="Obteniendo registros..."
      hide-default-footer
      no-data-text="Sin registros."
      disable-sort
      :page.sync="page"
      :items-per-page="rowsPerPage"
      @page-count="pageCount = $event"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="numero"
      show-expand
    >
      <template v-slot:[`item.importe`]="{ item }">
        {{ formatImport(item.importe) }}
      </template>
      <template v-slot:[`item.emitido_el`]="{ item }">
        {{ fechaEmitido(item.emitido_el) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- VER DETALLES -->
        <!-- GENERAR REPORTE PDF -->
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              color="red darken-2"
              @click="generarReporte(item)"
            >
              <v-icon>mdi-file-download</v-icon>
            </v-btn>
          </template>
          <span>Generar reporte</span>
        </v-tooltip>

        <!-- DESCARGAR COMPROBANTE -->
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              color="orange"
              icon
              v-on="on"
              @click="descargarComprobante(item)"
            >
              <v-icon>mdi-folder-arrow-down</v-icon>
            </v-btn>
          </template>
          <span>Descargar comprobante</span>
        </v-tooltip>
      </template>

      <!-- EXPANDED -->
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 1% !important"
          align="center"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Código de regimen</th>
              <th>Gravamen</th>
              <th>Retener</th>
              <th>Total retención</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dat in item.detalles" v-bind:key="dat.id" height="35px">
              <td v-text="dat.id" width="12%"></td>
              <td v-text="dat.codigo_regimen" width="20%"></td>

              <td width="25%">{{ dat.gravamen }}</td>
              <td width="15%">
                {{ dat.retener ? "Si" : "No" }}
              </td>
              <td width="30%">
                {{ formatImportFloat(dat.total_retencion) }}
              </td>
            </tr>
          </tbody>
        </td>
      </template>

      <!-- FOOTER -->
      <template v-slot:footer>
        <v-row class="no-gutters mt-2 mx-2">
          <v-flex xs12>
            <span>Mostrar</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn dark text color="primary" class="ml-2" v-on="on">
                  {{ rowsPerPage }}
                  <v-icon>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in rowsPerPageArray"
                  :key="index"
                  @click="rowsPerPage = number"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-row>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>
  </LayoutPage>
</template>

<script>
import LayoutPage from "../../../components/LayoutPage.vue";
import formatDate from "../../../utils/formatDate";
import formatImport from "../../../utils/formatImport";
import { formatImportFloat } from "../../../utils/formatImport";
import retencionService from "../../../services/reportes/retenciones";
import { mapGetters } from "vuex";
import moment from "moment";
import { toByteArray } from "base64-js";

export default {
  name: "ReportesRetenciones",
  components: { LayoutPage },
  data() {
    return {
      panel: 0,

      mes: moment().format("YYYY-MM-DD"),
      menu: false,

      headers: [
        { text: "CUIT", value: "cuit" },
        { text: "NÚMERO", value: "numero" },
        { text: "EMITIDO EL", value: "emitido_el" },
        { text: "GRAVAMEN", value: "gravamen" },
        { text: "IMPORTE", value: "importe" },
        { text: "ACCIONES", value: "actions" },
      ],

      retenciones: [],
      loading: false,
      loadingGenerar: false,

      //paginacion
      page: 1,
      pageCount: 0,
      rowsPerPage: 10, // cantidad de páginas por defecto

      rowsPerPageArray: [10, 30, 50, 100],

      expanded: [],

      rutaReporte: "",

      formatDate,
      formatImport,
      formatImportFloat,
      moment,
    };
  },

  computed: {
    ...mapGetters(["getCurrentClientId"]),

    esMesActual() {
      const mesActual = moment().format("MMMM");

      return mesActual === moment(this.mes).format("MMMM");
    },
  },

  watch: {
    getCurrentClientId() {
      this.getData();
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      const params = {
        ClienteId: this.getCurrentClientId,
      };

      try {
        this.loading = true;
        const data = await retencionService.getComprobantes(params);
        this.loading = false;

        this.retenciones = data?.data || [];
      } catch (error) {
        this.loading = false;
        // console.error("error: ", error);
        this.$toastr.e(
          error?.response?.data?.message || "Error al obtener las retenciones"
        );
      }
    },

    async generarCertificacion() {
      const fechaActual = moment().format("YYYY-MM-DD");

      const primerDia = moment(this.mes)
        .startOf("month")
        .format("YYYY-MM-DD");

      const ultimoDia = moment(primerDia)
        .endOf("month")
        .format("YYYY-MM-DD");

      const params = {
        FechaInicio: primerDia,
        FechaFin: this.esMesActual ? fechaActual : ultimoDia,
        ClienteId: this.getCurrentClientId,
      };

      try {
        this.loadingGenerar = true;
        await retencionService.generarCertificacion(params);
        this.loadingGenerar = false;

        this.getData();
      } catch (error) {
        this.loadingGenerar = false;
        // console.error("error: ", error);
        this.$toastr.e(
          error?.response?.data?.message || "Error aliquidar Retenciones"
        );
      }
    },

    async liquidarRetenciones() {
      const fechaActual = moment().format("YYYY-MM-DD");

      const primerDia = moment(this.mes)
        .startOf("month")
        .format("YYYY-MM-DD");

      const ultimoDia = moment(primerDia)
        .endOf("month")
        .format("YYYY-MM-DD");

      const params = {
        FechaInicio: primerDia,
        FechaFin: this.esMesActual ? fechaActual : ultimoDia,
        ClienteId: this.getCurrentClientId,
      };

      try {
        this.loadingGenerar = true;
        await retencionService.liquidarRetenciones(params);
        this.loadingGenerar = false;

        this.getData();
      } catch (error) {
        this.loadingGenerar = false;
        // console.error("error: ", error);
        this.$toastr.e(
          error?.response?.data?.message || "Error aliquidar Retenciones"
        );
      }
    },

    fechaEmitido(fecha) {
      let format = moment(fecha).format("YYYY-MM-DD");

      return moment(format).isBefore("2000-01-01") ? "-" : formatDate(fecha);
    },

    async generarReporte(item) {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Esta seguro?",
        text:
          "Se creara el reporte en pdf de comprobante de retencion, y se enviará al cliente",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Si, continuar",
        denyButtonText: "Cancelar",
      });

      if (isConfirmed) {
        try {
          this.loading = true;
          const params = {
            ReporteId: item.reporte_rrm.id,
            RutaFile: item.reporte_rrm.ruta_file,
          };
          const data = await retencionService.generarReporte(params);

          const base64Data = data?.data?.content.split(",")[1]; //base64 de la respuesta
          const fileName = data?.data?.file_name;
          const decodedData = toByteArray(base64Data); // convertir base64 a bytes

          const blob = new Blob([decodedData], { type: "application/pdf" }); //blob para descargar
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();

          this.$toastr.s(
            data?.data?.message || "Reporte generado exitosamente"
          );

          this.loading = false;
          this.getData();
        } catch (error) {
          this.loading = false;
          // console.error(error);
          this.$toastr.e(
            error?.response?.data?.message || "Error al generar reporte "
          );
        }
      }
    },
    async descargarComprobante(item) {
      const params = {
        ComprobanteId: item.id,
        ClienteId: this.getCurrentClientId,
      };

      try {
        this.loading = true;
        const data = await retencionService.descargarComprobante(params);

        const base64Data = data?.data?.content.split(",")[1]; //base64 de la respuesta
        const fileName = data?.data?.file_name;
        const decodedData = toByteArray(base64Data); // convertir base64 a bytes

        const blob = new Blob([decodedData], { type: "application/pdf" }); //blob para descargar
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();

        this.$toastr.s(data?.message || "Comprobante generado exitosamente");
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
        this.$toastr.e(
          error?.response?.data?.message || "Error al descargar comprobante"
        );
      }
    },
  },
};
</script>
